/* variables */
$cardBgColor: $white;
$cardSubTitleColor: $darkGray;
$cardTitleRecordCountColor: $darkGray;
$cardShadowColor: rgba($purple700, 0.157);
$separateLineColor: $gray;
/**/

/* container */
.container {
  padding: 32px 44px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shadow {
  box-shadow: 0px 10px 30px $cardShadowColor;
}

.card-container {
  margin-bottom: 60px;
  .card-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .large {
      font-weight: 600;
      line-height: 41px;
    }
  }
  .card-title-record-count {
    margin-left: 4px;
    color: $cardTitleRecordCountColor;
    transform: translateY(3px);
  }
  .card-content {
    width: 100%;
    padding: 24px 32px;
    border-radius: 16px;
    background-color: $cardBgColor;
    position: relative;
    .card-item {
      padding-bottom: 20px;
      word-break: break-word;
      &.with-bottom-line {
        padding-bottom: 24.5px;
        margin-bottom: 24.5px;
        position: relative;
        :after {
          content: '';
          width: calc(100% + 64px);
          height: 1px;
          position: absolute;
          left: -32px;
          bottom: 0;
          background-color: $separateLineColor;
        }
      }
      .card-item-text {
        font-weight: 400;
        letter-spacing: 0;
        align-self: center;
        &.large {
          font-weight: 600;
          line-height: 41px;
        }
        .link {
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
        }
      }
      .card-sub-title {
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: $cardSubTitleColor;
        letter-spacing: 0.7px;
        margin-bottom: 4px;
      }
    }
    > :last-child {
      padding-bottom: 0px;
    }
  }
}

@include rwd(below, sm) {
  .container {
    padding: 16px;
  }
}
/**/
