$btnPageDisableFontColor: $gray;
$btnPageFontColor: $black;
$btnPageCurrentColor: $white;
$btnPageItemBorderColor: $gray;

$btnPageDisableBGColor: $white;
$btnPageHoverBGColor: $purple200;
$btnPageActiveBGColor: $purple200;
$btnPageCurrentBGColor: $purple500;

$btnPageHoverBorderColor: $purple500;
$btnPageActiveBorderColor: $purple500;

$jumpToFontColor: $darkGray;

$inputFontColor: $black;
$inputBGColor: $white;
$inputBorderNormalColor: $gray;
$inputBorderHoverColor: $purple700;
$inputBorderActiveColor: $purple700;
$inputBorderFocusColor: $purple500;

.ant-pagination-next.ant-pagination-next,
.ant-pagination-prev.ant-pagination-prev {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  margin-right: -1px;
  z-index: 500;
  border: 1px solid $btnPageItemBorderColor;
  color: $btnPageFontColor;
  background-color: $btnPageDisableBGColor;

  &:not(.ant-pagination-disabled) {
    cursor: default;
    @include hover {
      background-color: $btnPageHoverBGColor;
      border-color: $btnPageHoverBorderColor;
    }
    &:active {
      background-color: $btnPageHoverBGColor;
      border-color: $btnPageHoverBorderColor;
    }
  }
}

.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled {
  cursor: default;
  @include svgIconColor($btnPageDisableFontColor);
  a {
    cursor: default;
    color: $btnPageDisableFontColor;
  }
}

.ant-pagination-item.ant-pagination-item {
  position: relative;
  margin-right: -1px;
  min-width: 40px;
  min-height: 40px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  border-color: $btnPageItemBorderColor;
  border-radius: 0px;
  &:hover,
  &:focus {
    border-color: $btnPageItemBorderColor;
  }
  &:not(.ant-pagination-disabled):not(.ant-pagination-item-active) {
    &:hover {
      z-index: 501;
      background-color: $btnPageHoverBGColor;
      border-color: $btnPageHoverBorderColor;
      a {
        color: $btnPageFontColor;
      }
    }
  }
  &.ant-pagination-item-active {
    z-index: 500;
    background-color: $btnPageCurrentBGColor;
    border-color: $btnPageActiveBorderColor;
    a {
      color: $btnPageCurrentColor;
    }
  }
  &.left {
    border-radius: 8px 0px 0px 8px;
  }
  &.right {
    border-radius: 0px 8px 8px 0px;
  }
  &.show {
    display: flex;
  }
  &.hide.hide {
    display: none;
  }
}

.ant-pagination-pagejumper {
  color: $jumpToFontColor;
  .jumpPageInput.jumpPageInput {
    width: 60px;
    height: 40px;
    margin-left: 6px;
    color: $inputFontColor;
    background-color: $inputBGColor;
    border-color: $inputBorderNormalColor;
    border-radius: 8px;
    font-size: initial;
    &:hover {
      border-color: $inputBorderHoverColor;
    }
    &:active {
      border-color: $inputBorderActiveColor;
      box-shadow: none;
    }
    &:focus {
      background-color: $inputBGColor;
      border-color: $inputBorderFocusColor;
      box-shadow: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      cursor: default;
      &:hover,
      &:focus,
      &:active {
        border-color: $btnPageDisableFontColor;
      }
    }
  }
}

/* override ant design */
.ant-pagination.ant-pagination {
  display: flex;
  a {
    font: 600 15px/22px $systemFont;
    color: $btnPageFontColor;
  }
}
.pagination-container.pagination-container {
  display: flex;
  a,
  li {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-pagination-jump-prev.ant-pagination-jump-prev.ant-pagination-jump-prev {
  display: none;
  .ant-pagination-item-1:first-child {
    display: none;
  }
}
.ant-pagination-jump-next.ant-pagination-jump-next.ant-pagination-jump-next {
  display: none;
  + .ant-pagination-item {
    display: none;
  }
}

.ant-pagination-prev.ant-pagination-prev,
.ant-pagination-next.ant-pagination-next {
  border-radius: 0px;
  transition: all 0s;
}
.ant-pagination-item.ant-pagination-item.ant-pagination-item {
  transition: all 0s;
}
/**/
