.disabled-ripple {
  &:after {
    display: none !important;
  }
}

.fill-none {
  fill: none;
}

.disable-scroll {
  position: fixed;
  overflow: hidden;
}
