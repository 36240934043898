$inputBorderColor: $purple500;
$inputBorderHoverColor: $purple700;
$inputIconColor: $purple500;
$inputBgColor: $white;
$inputLabelColor: $darkGray;
$inputShadowColor: rgba($purple700, 0.157);
$inputInvalidColor: $red;

%basicInput {
  width: 100%;
  height: 40px;
  border-color: $inputBorderColor;
  border-radius: 20px;
  &:hover {
    border-color: $inputBorderHoverColor;
  }
  &:focus {
    border-color: $inputBorderColor;
    box-shadow: 0 10px 30px $inputShadowColor;
  }
}

/* input */
.input {
  transition: all 0.3s;
  input {
    transition: all 0.3s;
  }
}

input {
  &::placeholder {
    opacity: 0;
    transition: all 0.3s;
  }
  &:active,
  &:focus {
    &::placeholder {
      opacity: 1;
    }
  }
}

input.ant-input {
  color: $black;
  border: 1px solid $inputLabelColor;
  background-color: $inputBgColor;
  padding: 10px;
  border-radius: 8px;
  font-size: initial;
  &.invalid {
    border-color: $inputInvalidColor;
  }
  &:hover {
    border-color: $inputBorderHoverColor;
  }
  &:focus,
  &:active {
    border-color: $inputBorderColor;
    box-shadow: none;
  }
}

.input.input:not(.ant-input-affix-wrapper) {
  @extend %basicInput;
  padding: 8px 22px 10px 22px;
}
/**/
/* form input */
.form-input {
  label {
    color: $inputLabelColor;
    display: inline-block;
    margin-bottom: 4px;
  }
}
/**/
/* suffix input */
.input.input.ant-input-affix-wrapper {  
  height: 40px;
  .icon {
    color: $inputIconColor;
    transition: all 0.3s;
    font-size: 14px;
  }
  // .ant-input-suffix {
  //   position: absolute;
  //   right: 12px;
  // }
  input:not(:disabled) {
    @extend %basicInput;
    background-color: transparent;
    padding: 8px 44px 10px 22px;
    &:hover {
      ~ .ant-input-suffix {        
        .icon {
          color: $inputBorderHoverColor;
        }
      }
    }
    &:focus {
      background-color: $inputBgColor;
      ~ .ant-input-suffix {        
        .icon {
          color: $inputIconColor;
        }
      }
    }
  }
}
/**/
/* search input */
.input.search-input {
  width: 160px;
  &.isActive {
    width: 480px;
  }
}
/**/
