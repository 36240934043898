/* reduce browser inconsistencies */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  --antd-wave-shadow-color: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  &:focus {
    outline: none;
  }
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    width: 0px; // Safari and Chrome
    height: 0px; // Safari and Chrome
  }
}