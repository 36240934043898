$btnBgColor: $white;
$btnLinkColor: $darkGray;
$btnLinkActiveColor: $purple500;
$btnLinkCurrentColor: $black;
$btnShadowColor: rgba($purple700, 0.157);

$btnContainedColor: $white;
$btnContainedBgColor: $purple500;
$btnContainedHoverBgColor: $purple600;
$btnContainedActiveBgColor: $purple800;
$btnContainedDisableBgColor: rgba($purple500, 0.2);

$btnIconColor: $purple200;
$btnIconHoverColor: $purple500;
$btnIconDisabledColor: $lightGray;

$btnTextNormalColor: $purple500;
$btnTextHoverColor: $purple700;

.btn-navi,
.ant-btn.btn-contained,
.ant-btn.btn-icon,
.ant-btn.btn-text {
  font-weight: 600;
  border: none;
  box-shadow: none;
  background: none;

  &:hover,
  &:focus,
  &:active {
    border: none;
    box-shadow: none;
  }
}

.btn-fake {
  min-height: 56px;
  min-width: 232px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: $btnLinkColor;
  background-color: $btnBgColor;
  @include svgIconColor($btnLinkColor);

  .icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    vertical-align: middle;
  }
}

.btn-navi {
  min-height: 56px;
  min-width: 232px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: $btnLinkColor;
  background-color: $btnBgColor;
  @include svgIconColor($btnLinkColor);

  .icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    vertical-align: middle;
  }

  &:focus,
  &:hover,
  &:active {
    color: $btnLinkActiveColor;
    @include svgIconColor($btnLinkActiveColor);
  }

  &:active {
    box-shadow: $btnShadowColor 0px 3px 10px;
  }

  &.isCurrent,
  &.isCurrent:focus {
    @include svgIconColor($btnLinkActiveColor);
    border-radius: 16px;
    z-index: 1200;
    color: $btnLinkCurrentColor;
    box-shadow: $btnShadowColor 0px 10px 30px;

    &:hover {
      color: $btnLinkActiveColor;
    }
  }
}

.ant-btn.btn-contained {
  height: 40px;
  padding: 10px 24px;
  line-height: 10px;
  font-size: 14px;
  letter-spacing: 0;
  border-radius: 8px;
  color: $btnContainedColor;
  background-color: $btnContainedBgColor;

  &:focus,
  &:hover {
    color: $btnContainedColor;
    background-color: $btnContainedHoverBgColor;
  }

  &:active {
    color: $btnContainedColor;
    background-color: $btnContainedActiveBgColor;
  }

  &:disabled {
    background-color: $btnContainedDisableBgColor;
  }
}

.ant-btn.btn-icon,
.btn-icon {
  width: 36px;
  height: 36px;
  padding: 0;
  @include svgIconColor($btnIconColor);

  &:focus,
  &:active {
    @include svgIconColor($btnIconHoverColor);
  }

  @include hover {
    @include svgIconColor($btnIconHoverColor);
  }

  &:disabled {
    @include svgIconColor($btnIconDisabledColor);
  }
}

.ant-btn.btn-text {
  @extend %link;
}

/**/