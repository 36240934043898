$linkColor: $purple500;
$linkHoverColor: $purple700;

%verticalSpreadSpace {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flexCenterAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

%link {
  text-align: left;
  color: $linkColor;
  transition: all 0.15s;
  font-size: 13px;
  &:hover,
  &:focus,
  &:active {
    color: $linkColor;
  }
  @media (pointer: fine) {
    &:hover,
    &:focus,
    &:active {
      color: $linkHoverColor;
    }
  }
}
