/* palette */
$black: #262525;
$white: #fff;
$darkGray: #929aab;
$gray: #dee0e6;
$lightGray: #eeeff2;
$red: #fe5965;

$purple800: #5439cc;
$purple700: #9852f9;
$purple600: #9176ff;
$purple500: #6b48ff;
$purple400: #ae9aff;
$purple300: #98bcff;
$purple200: #e1daff;
$purple100: #dfe2fe;

$orange500: #ff7959;
$orange400: #ffaf65;
$orange200: #ffe7d0;

$turquoise500: #03c8a8;
$turquoise400: #68e3cf;
$turquoise300: #a9f1e5;
$turquoise200: #cff8f1;

$gradStart: (
  'purple': $purple300,
  'orange': $orange400,
  'turquoise': $turquoise400,
  'lilac': $purple100
);
$gradEnd: (
  'purple': $purple600,
  'orange': $orange500,
  'turquoise': $turquoise500,
  'lilac': $lightGray
);
/**/

/* animation properties */
$animationProperties: (
  slide-in-fwd-center: slide-in-fwd-center 0.3s cubic-bezier(0.23, 1, 0.32, 1)
    both,
  slide-out-bck-center: slide-out-bck-center 0.3s
    cubic-bezier(0.165, 0.84, 0.44, 1) both,
  // circle-loading: circle-rotate 0.75s infinite linear both
);
/**/

/* font family */
$systemFont: 'Open Sans', Helvetica, Arial, 'PingFang TC', 微軟正黑體,
  'Microsoft JhengHei', sans-serif;
/**/

/* breakpoint */
$breakpoint: (
  xxxl: 1920px,
  xxl: 1600px,
  xl: 1440px,
  l: 1366px,
  ml: 1280px,
  m: 1152px,
  sm: 960px,
  s: 720px,
  xs: 540px,
  xxs: 480px,
  xxxs: 360px
);
/**/
