$selectBtnBgColor: transparent;
$selectBtnActiveBgColor: rgba($purple500, 0.2);
$selectBtnLabelColor: $black;
$selectBtnIconColor: $purple500;

$selectMenuBgColor: $white;
$selectMenuShadowColor: rgba($purple700, 0.157);
$selectItemColor: $darkGray;
$selectItemBgColor: transparent;
$selectItemHoverBgColor: $lightGray;
$selectItemCurrentBgColor: $purple500;

.selector {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.select-button {
    display: flex;
    justify-content: center;
    height: 40px;
    padding: 8px 8px 8px 12px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: $selectBtnBgColor;
    transition: all 0.3s;
    @include svgIconColor($selectBtnIconColor);
    &:hover {
        background-color: $selectBtnActiveBgColor;
    }
    &.menu-open {
        background-color: $selectBtnActiveBgColor;
    }
    .label {
        margin: 0px 4px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.75px;
        color: $selectBtnLabelColor;
    }
}

.select-menu-align {
    position: relative;
}

.select-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 8px 0;
    overflow: hidden;
    width: 120px;
    border-radius: 16px;
    background-color: $selectMenuBgColor;
    box-shadow: 0px 10px 30px $selectMenuShadowColor;
    transition: all 0.3s;
    &.hide {
        display: none;
    }
    .select-item {
        cursor: pointer;
        height: 40px;
        border: none;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.75px;
        color: $selectItemColor;
        background-color: $selectItemBgColor;
        &:hover {
            background-color: $selectItemHoverBgColor;
        }
        &.current {
            color: $selectItemCurrentBgColor;
        }
    }
}