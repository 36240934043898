@import 'src/styles/base/main';
@import 'src/styles/inbox.scss';

#mobile-checker {
  display: none;
  opacity: 0;
}

@include rwd(below, sm) {
  #mobile-checker {
    opacity: 1;
  }
}
