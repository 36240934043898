$tableShadowColor: rgba($purple700, 0.157);
$tableBgColor: $white;
$tableHeaderColor: $darkGray;
$tableOddBodyBgColor: $lightGray;
$tableEvenBodyBgColor: $white;
$tableSpinDotBgColor: $purple500;
$tableSorterIconColor: $purple500;
$tableBorderRadius: 16px;
$tableEmptyImgColor: $gray;
.table-page {
  width: 100%;
}

.title-container {
  margin-bottom: 20px;
  .title {
    display: flex;
    align-items: center;
    .switch {
      margin-left: 16px;
    }
  }
}

.table-with-pagination {
  overflow: scroll;
  box-shadow: 0px 10px 30px $tableShadowColor;
  border-radius: $tableBorderRadius;
  background: $tableBgColor;
}

.table {
  thead tr th {
    padding-left: 32px;
    background: $tableBgColor;
    color: $tableHeaderColor;
    font-weight: 600;
  }
  tbody tr td {
    padding-left: 32px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.75px;
    .link {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.75px;
      font-weight: 600;
    }
  }
  &.alternate-row {
    tr:nth-child(odd) td {
      transition: all 0s;
      background: $tableOddBodyBgColor;
    }
    tr:nth-child(even) td {
      transition: all 0s;
      background: $tableEvenBodyBgColor;
    }
  }
  th.ant-table-row-cell-break-word.ant-table-row-cell-break-word {
    position: relative;
    padding: 16px 2px 16px 16px;
  }
  td.ant-table-row-cell-break-word.ant-table-row-cell-break-word {
    position: relative;
    padding: 24px 2px 24px 16px;
  }
}
.auction-info.auction-info.auction-info.auction-info  {
  th,
  td {
    padding-left: 32px;
  }
}
.page-control {
  display: flex;
  justify-content: space-between;
  height: 88px;
  .page-size,
  .page-change {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $tableHeaderColor;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .dropdown {
      margin: 0px 6px 0px 32px;
    }
    .ant-pagination-pagejumper {
      height: 40px;
      margin-left: 10px;
      margin-right: 24px;
    }
  }
}
.empty-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $tableEmptyImgColor;
  .empty-img {
    width: 80px;
    height: 80px;
    @include svgIconColor($tableEmptyImgColor);
  }
  .empty-text {
    width: 100px;
    height: 30px;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0;
  }
}
@include rwd(below, l) {
  li.item-hide.item-hide.item-hide {
    display: none;
  }
  li.item-default.item-default.item-default {
    display: flex;
  }
}
@include rwd(below, m) {
  .page-control.page-control {
    height: 144px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    .page-size.page-size {
      margin-bottom: 24px;
    }
    .page-size .dropdown {
      margin-left: 0px;
    }
    .page-change.page-change {
      margin-top: 24px;
    }
    .page-change .ant-pagination-pagejumper {
      margin-right: 0px;
    }
  }
}

@include rwd(below, s) {
  .title-container .title {
    justify-content: space-between;
  }
  li.ant-pagination-item.ant-pagination-item.ant-pagination-item.ant-pagination-item {
    min-height: 32px;
    height: 32px;
    min-width: 32px;
    a {
      font: 600 13px/20px $systemFont;
    }
    letter-spacing: 0;
    padding: 6px;
    .icon {
      height: 20px;
      width: 20px;
    }
  }
  li.ant-pagination-next.ant-pagination-next,
  li.ant-pagination-prev.ant-pagination-prev {
    position: relative;
    min-width: 0px;
    min-height: 0px;
    height: 32px;
    width: 32px;
  }
  .page-control.page-control {
    height: 128px;
    .ant-pagination-pagejumper.ant-pagination-pagejumper {
      height: 32px;
      letter-spacing: 0;
      line-height: 20px;
      font-size: 13px;
    }
    .jumpPageInput.jumpPageInput {
      width: 40px;
      height: 32px;
      line-height: 20px;
    }
    .page-size.page-size {
      line-height: 20px;
      font-size: 13px;
    }
    .page-size .dropdown .dropdown-button {
      letter-spacing: 0px;
      padding: 6px 6px 6px 12px;
      height: 32px;
      line-height: 20px;
      font-size: 13px;
      .icon {
        height: 20px;
        width: 20px;
      }
    }
  }
  ul.dropdown-menu.dropdown-menu {
    .dropdown-item {
      height: 32px;
      line-height: 20px;
      font-size: 13px;
      letter-spacing: 0px;
      padding: 6px 6px 6px 12px;
    }
  }
}

@include rwd(below, xxs) {
  .ant-pagination-pagejumper.ant-pagination-pagejumper {
    display: none;
  }
  .ant-pagination-item.ant-pagination-item.ant-pagination-item {
    display: none;
  }
  .ant-pagination-item.ant-pagination-item.right,
  .ant-pagination-item.ant-pagination-item.left,
  .ant-pagination-item-active.ant-pagination-item-active.ant-pagination-item-active {
    display: block;
  }
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot.ant-spin-dot {
  top: 60%;
}
.ant-spin-dot-item.ant-spin-dot-item {
  background-color: $tableSpinDotBgColor;
}
.sorter-none {
  .ant-table-column-has-sorters.ant-table-column-has-sorters.ant-table-column-has-sorters {
    cursor: default;
    .ant-table-column-sorter-down,
    .ant-table-column-sorter-up {
      display: none;
    }
  }
}
// Override default ant design style for sorting arrow icon
.with-sorting-arrows.with-sorting-arrows.with-sorting-arrows {
  .ant-table-column-sorter-inner-full {
    margin-top: 0px;
  }
}
/* override ant design */
.has-pagination {
  .ant-table-body,
  .ant-table-scroll {
    border-radius: $tableBorderRadius $tableBorderRadius 0 0;
  }
}
.ant-table-thead.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters {
  &:hover:before,
  &:before {
    background: $tableBgColor;
  }
}
.table {
  &.alternate-row.alternate-row.alternate-row.alternate-row {
    tr:nth-child(odd) td {
      transition: all 0s;
      background: $tableOddBodyBgColor;
    }
    tr:nth-child(even) td {
      transition: all 0s;
      background: $tableEvenBodyBgColor;
    }
  }
}
.ant-table-tbody.ant-table-tbody > tr {
  .td.ant-table-column-sort,
  &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: $tableBgColor;
  }
}

.ant-table-column-has-sorters.ant-table-column-sort {
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    &.on.on.on {
      color: $tableSorterIconColor;
    }
    &.off.off.off {
      display: none;
    }
  }
}
.ant-table-column-has-sorters:not(.ant-table-column-sort) {
  &:hover {
    .ant-table-column-sorter-down {
      &.off.off {
        color: $tableSorterIconColor;
      }
    }
  }
  .ant-table-column-sorter-down {
    &.off.off {
      color: $tableHeaderColor;
    }
  }
  .ant-table-column-sorter-up {
    &.off.off {
      display: none;
    }
  }
}

.ant-table-placeholder.ant-table-placeholder {
  border-radius: 0;
}
/**/
