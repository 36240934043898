@import 'src/styles/utils/main';

$sidebarShadowColor: rgba($purple700, 0.28);
$sidebarBackgroundColor: $white;
$sidebarOverlayBGColor: rgba($black, 0.7);
$sidebarDividingLineBGColor: $gray;
$sidebarTestnetBGColor: $darkGray;
$sidebarTestnetTextColor: $white;
$sidebarTestnetColor: $darkGray;
$sidebarTestnetActiveColor: $purple500;
$topbarShadowColor: rgba($purple700, 0.28);
$topbarBackgroundColor: $white;
$topbarMenuIconColor: $darkGray;
$topbarSearchbarBGColor: $lightGray;
$inputBorderColor: $purple500;
$loadingIndicatorBGColor: $purple500;
$footerColor: $darkGray;

.layout {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  width: 280px;
  height: 100vh;
  overflow: scroll;
  transition: all 0.3s;
  background-color: $sidebarBackgroundColor;
  box-shadow: 0px 10px 30px $sidebarShadowColor;

  .sidebar-inner {
    min-height: 680px;
    position: relative;
    height: 100vh;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.isMoving {
    transition: none;
  }

  .logoLink {
    margin: 40px 0px;

    .logo {
      width: 180px;
      height: 60px;
    }
  }

  .closeBtn {
    position: absolute;
    left: 4px;
    top: 4px;
    height: 48px;
    width: 48px;
    padding: 0;
    cursor: pointer;
    border: none;
    background-color: $sidebarBackgroundColor;
    @include svgIconColor($topbarMenuIconColor);
  }

  .sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-dividingLine {
    height: 1px;
    width: 200px;
    margin: 16px;
    background-color: $sidebarDividingLineBGColor;
  }

  .sidebar-testnet {
    letter-spacing: 0.8px;
    @include svgIconColor($sidebarTestnetColor);

    // &:focus,
    // &:hover,
    // &:active {
    //   color: $sidebarTestnetActiveColor;
    //   @include svgIconColor($sidebarTestnetActiveColor);
    //   .testnet-content {
    //     background-color: $sidebarTestnetActiveColor;
    //   }
    // }
    .icon {
      height: 20px;
      width: 20px;
      margin-left: 4px;
    }
  }

  .testnet-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 47px;
    background-color: $sidebarTestnetBGColor;
    border-radius: 4px;
    margin: 0px 4px;
    transition: all 0.3s;
  }

  .testnet-inner {
    display: inline-block;
    font-weight: 600;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.5px;
    transform: scale(0.83);
    color: $sidebarTestnetTextColor;

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  .sidebar-routeLinks {
    display: flex;
    flex-direction: column;

    .btn-navi:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  .sidebar-SNSLinks {
    display: flex;
    justify-content: center;
    margin: 40px 0px;

    .SNSLink-outer {
      margin: 0px -6px;
    }

    .btn-icon {
      margin: 0 6px;
    }
  }
}

.sidebar-overlay {
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  background-color: $sidebarOverlayBGColor;

  &.isOpen {
    display: block;
  }
}

.topbar {
  position: fixed;
  top: 0;
  z-index: 999;

  .topbar-inner {
    position: relative;
    width: 100vw;
    height: 52px;
    display: flex;
    justify-content: center;
    background-color: $topbarBackgroundColor;
    z-index: 1000;

    .menuBtn {
      position: absolute;
      left: 4px;
      top: 4px;
      padding: 0;
      border: none;
      height: 48px;
      width: 48px;
      cursor: pointer;
      @include svgIconColor($topbarMenuIconColor);
      background-color: $topbarBackgroundColor;
    }

    .logoLink {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      min-height: 48px;
      width: 106px;

      .topbar-logo {
        height: 30px;
        width: 90px;
      }
    }

    .lngSelector {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .searchbar {
    position: fixed;
    height: 48px;
    width: 100vw;
    transition: width 0.3s height 0s;
    z-index: 999;
    background-color: $topbarBackgroundColor;

    &.isMoving {
      transition: none;
    }
  }
}

.wrapper {
  min-height: 100vh;
  margin-left: 280px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  display: flex;
  justify-content: center;
}

.loadingIndicator {
  animation: loadingIndicator-active linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  animation-duration: 0s;
  position: fixed;
  height: 4px;
  width: calc(100% - 280px);
  top: 0;
  left: 280px;
  z-index: 1000;
  background-color: $loadingIndicatorBGColor;
  opacity: 0;
  transform-origin: left center;

  &.active {
    opacity: 1;
    animation-play-state: running;
    animation-duration: 1000s;
  }

  &.hide {
    opacity: 1;
    transition: height 0.3s;
    height: 0;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 48px 32px 40px 32px;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.75px;
  color: $footerColor;

  .link {
    font-size: 15px;
  }
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: gradient('lilac', 180deg);
}

@include rwd(below, sm) {
  .sidebar {
    box-shadow: none;

    .logoLink {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 16px;
      min-height: 48px;

      .logo {
        height: 30px;
        width: 90px;
      }
    }
  }

  .topbar {
    .searchbar {
      box-shadow: 0px 3px 10px $topbarShadowColor;
      padding: 0 8px;
      transition: width 0s;

      .input {
        width: 100%;
      }

      .ant-input.ant-input.ant-input {
        background-color: $topbarSearchbarBGColor;
        border: none;

        &:focus {
          border: 1px solid $inputBorderColor;
          box-shadow: none;
        }
      }
    }
  }

  .wrapper {
    margin-left: 0;
    margin-top: 100px;
  }

  .loadingIndicator {
    width: 100%;
    top: 0;
    left: 0px;
    z-index: 1005;
  }
}

@include rwd(above, sm) {
  .sidebar {
    .closeBtn {
      display: none;
    }
  }

  .topbar {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: 44px;
    top: 33px;

    .topbar-inner,
    .searchbar {
      position: relative;
      width: auto;
      background-color: transparent;

      .input.search-input.isActive {
        width: 300px;
      }
    }

    .topbar-inner {
      margin-left: 12px;

      .menuBtn,
      .logoLink {
        display: none;
      }

      .lngSelector {
        position: relative;
        right: 0px;
        top: 0px;
      }
    }
  }
}

@include rwd(above, m) {
  .topbar {
    .searchbar {
      .input.search-input.isActive {
        width: 480px;
      }
    }
  }
}

@media (pointer: coarse) {
  .layout {
    .sidebar {
      height: 100%;
    }
  }
}