html {
  body {
    font-family: $systemFont;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0;
    margin-bottom: 0;
    color: $black;
  }
  p {
    letter-spacing: 0.05em;
  }
}

html h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 85px;
}
html h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
}
html h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
}
html h4 {
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
}
html h5 {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}
html h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.timestamp {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;
  font-weight: 400;
}
