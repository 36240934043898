@keyframes slide-in-fwd-center {
  0% {
    transform: perspective(500px) translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: perspective(500px) translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-out-bck-center {
  0% {
    transform: perspective(500px) translateZ(0);
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: perspective(500px) translateZ(-1400px);
    opacity: 0;
  }
}
@keyframes loadingIndicator-active {
  0% {
    transform: scaleX(0);
  }
  0.01% {
    transform: scaleX(0.2);
  }
  0.025% {
    transform: scaleX(0.5);
  }
  0.04% {
    transform: scaleX(0.6);
  }
  0.06% {
    transform: scaleX(0.7);
  }
  0.1% {
    transform: scaleX(0.8);
  }
  0.2% {
    transform: scaleX(0.85);
  }
  1% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}

// @keyframes circle-rotate {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
