$tabHeaderColor: $darkGray;
$tabHeaderActiveColor: $purple500;
$tabInkBarColor: $purple500;

.ant-tabs.ant-tabs {
    margin: -30px;
    padding: 30px;
}

.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab {
    margin-left: 0px;
    margin-right: 32px;
    margin-bottom: 8px;
    padding: 0px 0px 10px 0px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: $tabHeaderColor;
    &:hover {
        color: $tabHeaderActiveColor;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 0;
        height: 4px;
        background-color: $tabInkBarColor;
        transition: width 0s;
    }
    &.ant-tabs-tab-active {
        color: $tabHeaderActiveColor;
        &::after {
            width: 100%;
            transition: width 0.3s;
        }
    }
}

/* override ant design */
.ant-tabs-ink-bar {
    display: none !important;
}

.ant-tabs-bar.ant-tabs-bar {
    border-bottom: 0;
}
/**/