$dropdownDisableColor: $gray;
$dropdownBGColor: $white;
$dropdownBorderColor: $purple500;
$dropdownBorderHoverColor: $purple700;
$dropdownIconColor: $purple500;
$dropdownIconHoverColor: $purple700;
$dropdownShadowColor: rgba($purple700, 0.157);
$dropdownItemSelectedColor: $purple200;
.dropdown.clicked {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 1px;
    width: calc(100% - 2px);
    height: 1px;
    background-color: $dropdownDisableColor;
    z-index: 501;
  }
}
.ant-dropdown.ant-dropdown.ant-dropdown.ant-dropdown.ant-dropdown {
  &.slide-up-leave {
    animation-duration: 0s;
  }
  &.slide-up-enter {
    animation-duration: 0s;
  }
}
.ant-btn.ant-btn.dropdown-button {
  position: relative;
  box-shadow: none;
  transition: all 0s;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  letter-spacing: 0.75px;
  font-weight: 600;
  line-height: 1.75;
  color: $black;
  background-color: $white;
  border: 1px solid $dropdownBorderColor;
  border-radius: 8px;
  z-index: 501;
  @include svgIconColor($dropdownIconColor);
  &:hover,
  &:focus {
    border-color: $dropdownBorderHoverColor;
    @include svgIconColor($dropdownIconHoverColor);
  }
  &.clicked {
    transition: all 0s;
    border-color: $dropdownBorderColor;
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;
    @include svgIconColor($dropdownIconColor);
  }
  &:disabled {
    cursor: default;
    color: $dropdownDisableColor;
    border-color: $dropdownDisableColor;
    background-color: $dropdownBGColor;
    @include svgIconColor($dropdownDisableColor);
  }
}

.ant-dropdown.ant-dropdown {
  min-width: auto !important;
  z-index: 500;
}

ul.dropdown-menu.dropdown-menu {
  padding: 0px 0px;
  transform: translate(0px, -6px);
  border: 1px solid $dropdownBorderColor;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 10px 30px $dropdownShadowColor;
  transition: all 0s;
  .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
    margin: 0;
    padding: 10px 12px;
    color: $black;
    transition: all 0s;
    &.selected {
      background-color: $dropdownItemSelectedColor;
    }
    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
  &.hide {
    display: none;
  }
}

/* override ant design */
.dropdown-button.dropdown-button {
  &:hover,
  &:focus {
    color: $black;
  }
}

.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item.ant-menu-item {
    margin: 0;
    &:hover {
      color: $black;
    }
  }
  .ant-menu-item-selected.ant-menu-item-selected {
    background-color: $white;
  }
}
/**/
