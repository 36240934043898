$switchUncheckedColor: $darkGray;
$switchCheckedColor: $purple500;

.switch {
  &.hide {
    display: none;
  }
  .ant-switch {
    width: 48px;
    margin-right: 8px;
    background-color: $switchUncheckedColor;
    &:after {
      left: 4px;
    }
  }
  .ant-switch-checked {
    background-color: $switchCheckedColor;
    &:after {
      left: calc(100% - 2px);
    }
  }
  .ant-switch-loading-icon,
  .ant-switch::after {
    top: 4px;
    width: 12px;
    height: 12px;
  }
  .switch-text {
    font-size: 13px;
    font-weight: 600;
    color: $switchUncheckedColor;
    transition: all 0.3s;
    &.isActive {
      color: $switchCheckedColor;
    }
  }
}
