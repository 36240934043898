/* svg color */
@mixin svgIconColor($color) {
  svg.icon {
    *:not(title):not(g):not(.fill-none) {
      fill: $color;
      transition: all 0.3s;
    }
  }
}
/**/

/* animation */
@mixin animation($name) {
  animation: map-get($map: $animationProperties, $key: $name);
}
/**/

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

/* media query */
@mixin rwd($direction, $size, $size2: null) {
  @each $name, $width in $breakpoint {
    @if ($size == $name) {
      @if ($direction == above) {
        @media (min-width: $width) {
          @content;
        }
      }
      @if ($direction == below) {
        @media (max-width: #{$width - 1}) {
          @content;
        }
      }
      @if ($direction == between) {
        @if ($size and $size2) {
          @media all and (min-width: map-get($map: $breakpoint, $key: $size)) and (max-width: map-get($map: $breakpoint, $key: $size2) - 1px) {
            @content;
          }
        }
      }
    }
  }
}
/**/
